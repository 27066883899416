<template>
    <v-app-bar app dark color="primary">
        <v-app-bar-nav-icon @click.stop="$emit('click')" />
            <v-spacer />
            <div @click="goTo('/dashboard')">
                <v-icon>mdi-home-city</v-icon>
                <span>&nbsp;{{ getCompanyName }}</span>
            </div>
            <v-spacer />
            <div @click="goTo('/users')">
                <v-icon>mdi-account</v-icon>
                <span>{{ userName }}</span>
            </div>
            <v-spacer />
    </v-app-bar>
</template>

<script>
export default {
    name: 'AppBar',

    data: () => ({
        userName: '',
    }),

    mounted() {
        this.userName = this.$store.state?.user?.name || '';
    },

    computed: {
        getCompanyName() {
            return this.$store.state.config?.nome || '';
        },
    },

    methods: {
        goTo(route) {
            this.$route.path != route && this.$router.push(route);
        },
    }
};
</script>

<style scoped>
div {
    cursor: pointer;
}
</style>
