<template>
    <v-app>
        <AppBar @click="drawer = !drawer" />
        <NavigationDrawer v-model="drawer" @toggle="drawer = $event" />
        <v-content style="background-color: #e0e0e0;">
            <slot name="container" />
        </v-content>
    </v-app>
</template>

<script>
import AppBar from './AppBar';
import NavigationDrawer from './NavigationDrawer';

export default {
    name: 'AppContainer',

    components: {
        AppBar,
        NavigationDrawer,
    },

    data: () => ({
        drawer: false,
    }),
};
</script>
