<template>
    <v-navigation-drawer
        v-model="drawer"
        :temporary="true"
        app
        overflow
        color="primary"
        dark
    >
        <v-list dense>
            <v-list-item link to=""> <!-- /account -->
                <div class="ma-2">
                    <v-avatar size="50">
                        <img :src="getAvatar" />
                    </v-avatar>
                </div>
                <v-list-item-content>
                    <v-list-item-title v-text="getUser.name" />
                    <v-list-item-subtitle v-text="getUser.email" />
                    <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider dark class="my-4"/>


            <div v-for="(menu, index) in menus" :key="index">
                <v-list-item link :to="menu.route">
                    <v-list-item-action>
                        <v-icon>{{ menu.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ menu.label }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </div>

            <v-divider dark class="my-4"/>

            <v-list-item @click="logout">
                <v-list-item-action>
                    <v-icon color="red">mdi-logout</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Sair</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'NavigationDrawer',

    props: ['value'],

    data: () => ({
        drawer: false,
    }),

    watch: {
        value(v) {
            this.drawer = v;
        },

        drawer(v) {
            this.$emit('toggle', v);
        }
    },

    computed: {
        getUser() {
            return this.$store.state.user || {};
        },

        getAvatar() {
            const name = this.getUser?.name || '';
            const nameLetters = name.split(' ').join('+');
            return 'https://ui-avatars.com/api/?name=' + nameLetters;
        },

        menus() {
            return [
                {
                    label: 'Inicio',
                    route: '/dashboard',
                    icon: 'mdi-home',
                },
                {
                    label: 'Usuários',
                    route: '/users',
                    icon: 'mdi-account',
                },
                {
                    label: 'Produtos',
                    route: '/produtos',
                    icon: 'mdi-barcode',
                },
                {
                    label: 'Pacotes',
                    route: '/pacotes',
                    icon: 'mdi-gift',
                },
                {
                    label: 'Seriais',
                    route: '/seriais',
                    icon: 'mdi-youtube-tv',
                },
                {
                    label: 'Vendas',
                    route: '/vendas',
                    icon: 'mdi-cart',
                },
                {
                    label: 'Loja',
                    route: '/',
                    icon: 'mdi-diamond-stone',
                },
                {
                    label: 'Configurações',
                    route: '/configuracoes',
                    icon: 'mdi-cog',
                },
            ];
        }
    },

    methods: {
        logout() {
            if (confirm('Deseja realmente SAIR?')) {
                sessionStorage.clear();
                this.$store.commit('setUser', {});
                this.$router.push('/login');
            }
        },
    }
};
</script>
